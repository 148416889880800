<template>
  <!-- 新闻-导航栏 -->
  <div class="news-navbar">
    <div class="navbar-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/news' }"
          >新闻中心</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {
  name: "newsNavbar",
  props: {
    title: {
      type: String,
      default: "新闻标题",
    },
  },
  methods: {
    viewDetails(item) {
      console.log({ item, routes: this.$route });
      this.$router.push(`/news/view/${item.id}`);
    }, // 查看详情
  },
};
</script>

<style lang="less" scoped>
// 导航栏
.news-navbar {
  width: 1200px;
  height: 56px;
  margin: 0 auto;
  padding: 0 30px;

  .navbar-breadcrumb {
    display: flex;
    align-items: center;
    height: 100%;
    border-bottom: 1px solid #e8e8e8;

    /deep/ .el-breadcrumb__inner,
    .el-breadcrumb__inner.is-link {
      color: #8c8c8c;
      font-weight: normal;
    }
  }
}
</style>
