<template>
  <!-- 新闻中心 -->
  <div>
    <!-- 横幅(新闻默认页) -->
    <product-brief
      height="180"
      v-if="isNewsDefault"
      :title="currentData && currentData.productBrief.title"
      :memo="currentData && currentData.productBrief.memo"
      :img-src="currentData && currentData.productBrief.imgSrc"
    />

    <!-- 面包屑(新闻详情页) -->
    <news-navbar v-else />

    <!-- 新闻容器 -->
    <div class="news-container">
      <!-- 主内容 -->
      <router-view />

      <!-- 侧边 -->
      <news-aside />
    </div>
  </div>
</template>

<script>
import newsAside from "./components/news-aside";
import newsNavbar from "./components/news-navbar";

export default {
  components: {
    newsAside,
    newsNavbar,
  },
  data() {
    return {
      currentData: {
        productBrief: {
          title: "新闻中心 NEWS",
          memo: [],
          imgSrc: "https://s1.ax1x.com/2020/07/27/aPrVRP.png",
        }, // 介绍组件数据
      },
    };
  },
  methods: {},
  computed: {
    isNewsDefault() {
      return this.$route.name == "newsDefault";
    }, // 是否为新闻默认页
  },
};
</script>

<style lang="less" scoped>
.news-container {
  width: 1200px;
  margin: 0 auto;
  padding: 60px 30px;
  display: flex;
  justify-content: space-between;
}
</style>
