<template>
  <!-- 侧边 -->
  <div class="news-side">
    <!-- 新闻分类 -->
    <div class="news-group">
      <p class="title">新闻分类</p>

      <div class="tree">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
    </div>

    <!-- 行业动态 -->
    <div class="news-dynamic">
      <p class="title">最新行业动态</p>

      <div class="dynamic-list">
        <div
          class="dynamic-card"
          v-for="(item, index) in dynamicList"
          :key="index"
          @click="viewDetails(item._links.self.href)"
        >
          <img :src="item.picture" alt="" />
          <div class="info">
            <p v-text="item.title" />
            <p class="time">{{ item.updatedAt | dateFormat }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNews, getNewsByType } from "@/api/news";

export default {
  name: "newsAside",
  data() {
    return {
      dynamicList: [
        {
          img: "https://s1.ax1x.com/2020/07/27/aPDfCq.png",
          title: "祝贺广东财经大学第十二届银行技能大赛成功举办",
          time: "2019-11-21 18:00",
        },
        {
          img: "https://s1.ax1x.com/2020/07/27/aPDfCq.png",
          title: "祝贺广东财经大学第十二届银行技能大赛成功举办",
          time: "2019-11-21 18:00",
        },
        {
          img: "https://s1.ax1x.com/2020/07/27/aPDfCq.png",
          title: "祝贺广东财经大学第十二届银行技能大赛成功举办",
          time: "2019-11-21 18:00",
        },
      ], // 动态列表
      treeData: [
        {
          title: "公司新闻",
          children: [],
        },
        {
          title: "行业动态",
          children: [],
        },
        {
          title: "媒体报道",
          children: [],
        },
      ], // 树
      defaultProps: {
        children: "children",
        label: function(data, node) {
          return data.children?.length > 0
            ? data.title + `（${data.children.length}）`
            : data.title;
        },
      }, // 树默认属性
      list: [], // 数据列表
      limitValue: 5, // 限制值
    };
  },
  created() {
    this.getNews();
    this.getDynamic();
  },
  methods: {
    async getNews() {
      let res = await getNews();
      let obj = {
        temp1: [],
        temp2: [],
        temp3: [],
        temp4: [],
      };

      for (let index = 0; index < res.data._embedded.news.length; index++) {
        const element = res.data._embedded.news[index];
        obj[`temp${element.type}`].push(element);
      }

      this.treeData[0].children = obj.temp1;
      this.treeData[1].children = obj.temp2;
      this.treeData[2].children = obj.temp3;
    }, // 获取新闻
    async getDynamic() {
      let res = await getNewsByType("2");
      this.dynamicList = res.data._embedded.news;
    }, // 获取行业动态
    viewDetails(href) {
      let id = href.split("/").pop();
      this.$router.push(`/news/view/${id}`);
    }, // 查看详情
    handleNodeClick(node) {
      if (node._links) {
        let id = node._links.self.href.split("/").pop();
        this.$router.push(`/news/view/${id}`);
      }
    }, // 点击节点
  },
};
</script>

<style lang="less" scoped>
// 侧边
.news-side {
  width: 360px;
  padding-left: 70px;
  // background-color: pink;

  .title {
    font-size: 18px;
    text-align: left;
    padding-bottom: 16px;
    color: var(--main-title_black);
    border-bottom: 1px solid #9b9b9b;
  }

  // 树
  /deep/ .tree {
    padding: 15px 0 30px;

    .el-tree-node__expand-icon {
      font-size: 16px;
      color: var(--main-title_black);
    }

    .el-tree-node__expand-icon.is-leaf {
      color: transparent;
    }

    .el-tree-node__label {
      font-size: 14px;
      color: #696969;
    }
  }

  // 动态列表
  .dynamic-list {
    padding: 20px 0 0 10px;

    .dynamic-card {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      cursor: pointer;

      img {
        width: 82px;
        height: 54px;
        object-fit: cover;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        text-align: left;
        margin-left: 10px;
        line-height: 18px;
        color: var(--main-title_black);

        .time {
          line-height: 1;
          color: var(--sub-title_gray);
        }
      }
    }
  }
}
</style>
